import { Snapshot } from '@models/snapshot';
import { emptySnapshotCompany } from '@models/snapshot-company';
import React, { createContext, useContext, useReducer } from 'react';
import SnapshotStatus from '../@enum/SnapshotStatus.enum';
import { snapshotReducer } from '../@reducers/Snapshot.reducer';
import SnapshotDispatch from '../@types/SnapshotDispatch';
import SnapshotState from '../@types/SnapshotState';

const SnapshotStateContext = createContext<SnapshotState>(undefined);
const SnapshotDispatchContext = createContext<SnapshotDispatch | undefined>(undefined);
const SnapshotProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const initialState: Snapshot = {
    version: '',
    submittedBy: '',
    period: '',
    endOfPeriod: '',
    endOfPeriodTh: '',
    fiscalYear: '',
    availableLanguages: [],
    company: emptySnapshotCompany(),
    status: SnapshotStatus.DRAFT,
    dataTh: {
      profile: '',
      remarkable: '',
      revenueStructure: [],
      businessPlan: '',
      significantRiskManagement: '',
      consistencyPlan: '',
      award: '',
      contact: {
        website: '',
        email: '',
        phone: '',
        address: '',
        others: '',
      },
      addOnLeft: {
        content: '',
        title: '',
      },
      addOnRight: {
        content: '',
        title: '',
      },
    },
  };
  const [state, dispatch] = useReducer(snapshotReducer, initialState);
  return (
    <SnapshotStateContext.Provider value={state}>
      <SnapshotDispatchContext.Provider value={dispatch}>{children}</SnapshotDispatchContext.Provider>
    </SnapshotStateContext.Provider>
  );
};

const useSnapshotState = (): SnapshotState => {
  const context = useContext(SnapshotStateContext);
  if (context === undefined) {
    throw new Error('useSnapshotState must be used within a SnapshotProvider');
  }

  return context;
};

const useSnapshotDispatch = (): SnapshotDispatch => {
  const context = useContext(SnapshotDispatchContext);
  if (context === undefined) {
    throw new Error('useSnapshotDispatch must be used within a SnapshotProvider');
  }
  return context;
};

export { SnapshotProvider, useSnapshotState, useSnapshotDispatch };
