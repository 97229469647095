import { Observable, from } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { AxiosResponse } from 'axios';
import axios from '@core/axios';

export interface UserTokenResponse {
  token: string;
  refreshToken: string;
}

export default class TokenService {
  private static key = 'token';
  private static keyRefresh = 'refresh-token';

  public static getToken(): string | null {
    return sessionStorage.getItem(this.key) || null;
  }

  public static setToken(token: string): void {
    sessionStorage.setItem(this.key, token || '');
  }

  public static clearToken(): void {
    sessionStorage.removeItem(this.key);
  }

  public static getRefreshToken(): string | null {
    return sessionStorage.getItem(this.keyRefresh) || null;
  }

  public static setRefreshToken(token: string): void {
    sessionStorage.setItem(this.keyRefresh, token);
  }

  public static clearRefreshToken(): void {
    sessionStorage.removeItem(this.keyRefresh);
  }

  public static refreshToken(): Observable<AxiosResponse<UserTokenResponse>> {
    const refreshToken = TokenService.getRefreshToken();
    return from(
      axios.post<UserTokenResponse>(`/auth/refreshToken`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
    ).pipe(
      tap(({ data }) => {
        TokenService.setToken(data.token);
        TokenService.setRefreshToken(data.refreshToken);
      }),
      delay(1000)
    );
  }
}
