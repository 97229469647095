import IFiscalYearInfo from '@interfaces/IFiscalYearInfo';
import IFormOption from '@interfaces/IFormOption';
import AppConfig from '@constants/app-config';
import IDateRange from '@interfaces/IDateRange';
import { Dates, DatesFactory } from '@core/factories/Dates';

class FiscalYearService {
  /**
   * createMonthRangeOptions
   *
   * @param fiscalEnd string a fiscal end string
   * @param locale th/en
   * @return list of option
   */
  public createMonthRangeOptions(fiscalEnd: string, locale: string): IFormOption[] {
    const today = DatesFactory.today();
    const currentYear = DatesFactory.today().year();
    const previousYear = currentYear - 1;
    const nextYear = currentYear + 1;
    const next2Year = currentYear + 2;
    const previousFiscalYear = this.createFiscalYearInfo(fiscalEnd, previousYear);
    const currentFiscalYear = this.createFiscalYearInfo(fiscalEnd, currentYear);
    const nextFiscalYear = this.createFiscalYearInfo(fiscalEnd, nextYear);
    const next2FiscalYear = this.createFiscalYearInfo(fiscalEnd, next2Year);
    const output: IFormOption[] = [];

    output.push(...this.findSnapshotMonthPeriods(today, previousYear, previousFiscalYear, currentFiscalYear, locale));
    output.push(...this.findSnapshotMonthPeriods(today, currentYear, currentFiscalYear, nextFiscalYear, locale));
    output.push(...this.findSnapshotMonthPeriods(today, nextYear, nextFiscalYear, next2FiscalYear, locale));

    return output;
  }

  /**
   * createSnapPeriodOptions
   * produce fiscal period options from given fiscal end.
   *
   * @param fiscalEnd string a fiscal end string
   * @return list of option
   *
   * Fiscal End of year X
   * The fiscal year, starting from some day and ending with in year X.
   *
   * Example:
   * FiscalEnd('31/5', 2020) yields a fiscal year of 1 June 2019 to 31 May 2020.
   */

  public createSnapPeriodOptions(fiscalEnd: string, locale: string): IFormOption[] {
    const today = DatesFactory.today();
    const currentYear = DatesFactory.today().year();
    const previousYear = currentYear - 1;
    const nextYear = currentYear + 1;
    const next2Year = currentYear + 2;
    const previousFiscalYear = this.createFiscalYearInfo(fiscalEnd, previousYear);
    const currentFiscalYear = this.createFiscalYearInfo(fiscalEnd, currentYear);
    const nextFiscalYear = this.createFiscalYearInfo(fiscalEnd, nextYear);
    const next2FiscalYear = this.createFiscalYearInfo(fiscalEnd, next2Year);
    const output: IFormOption[] = [];

    output.push(...this.findSnapshotPeriods(today, previousYear, previousFiscalYear, currentFiscalYear, locale));
    output.push(...this.findSnapshotPeriods(today, currentYear, currentFiscalYear, nextFiscalYear, locale));
    output.push(...this.findSnapshotPeriods(today, nextYear, nextFiscalYear, next2FiscalYear, locale));

    return output;
  }

  private findSnapshotMonthPeriods(day: Dates, year: number, fiscalYear: IFiscalYearInfo, nextFiscalYear: IFiscalYearInfo, locale: string): IFormOption[] {
    const output: IFormOption[] = [];
    const buddhistYear = year + 543;

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q1.from, to: fiscalYear.q2.to })) {
      const label = this.createMonthPeriodLabel(fiscalYear.q1.from, fiscalYear.q1.to, locale);
      output.push({
        label: `${label}`,
        value: `3M/${buddhistYear}`,
      });
    }

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q2.from, to: fiscalYear.q3.to })) {
      const label = this.createMonthPeriodLabel(fiscalYear.q1.from, fiscalYear.q2.to, locale);
      output.push({
        label: `${label}`,
        value: `6M/${buddhistYear}`,
      });
    }

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q3.from, to: fiscalYear.q4.to })) {
      const label = this.createMonthPeriodLabel(fiscalYear.q1.from, fiscalYear.q3.to, locale);
      output.push({
        label: `${label}`,
        value: `9M/${buddhistYear}`,
      });
    }

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q4.from, to: nextFiscalYear.q1.to })) {
      const label = this.createMonthPeriodLabel(fiscalYear.q1.from, fiscalYear.q4.to, locale);
      output.push({
        label: `${label}`,
        value: `YE/${buddhistYear}`,
      });
    }

    return output;
  }

  private findSnapshotPeriods(day: Dates, year: number, fiscalYear: IFiscalYearInfo, nextFiscalYear: IFiscalYearInfo, locale: string): IFormOption[] {
    const output: IFormOption[] = [];
    const buddhistYear = year + 543;
    const localeFiscalYear = locale === 'th' ? buddhistYear : year;

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q1.from, to: fiscalYear.q2.to })) {
      const label = this.createOptionLabel(fiscalYear.q1.from, fiscalYear.q1.to, locale);
      output.push({
        label: `3M/${localeFiscalYear} (${label})`,
        value: `3M/${buddhistYear}`,
      });
    }

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q2.from, to: fiscalYear.q3.to })) {
      const label = this.createOptionLabel(fiscalYear.q1.from, fiscalYear.q2.to, locale);
      output.push({
        label: `6M/${localeFiscalYear} (${label})`,
        value: `6M/${buddhistYear}`,
      });
    }

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q3.from, to: fiscalYear.q4.to })) {
      const label = this.createOptionLabel(fiscalYear.q1.from, fiscalYear.q3.to, locale);
      output.push({
        label: `9M/${localeFiscalYear} (${label})`,
        value: `9M/${buddhistYear}`,
      });
    }

    if (this.isAbleToCreateSnapshot(day, { from: fiscalYear.q4.from, to: nextFiscalYear.q1.to })) {
      const label = this.createOptionLabel(fiscalYear.q1.from, fiscalYear.q4.to, locale);
      output.push({
        label: `YE/${localeFiscalYear} (${label})`,
        value: `YE/${buddhistYear}`,
      });
    }

    return output;
  }

  private isAbleToCreateSnapshot(day: Dates, period: IDateRange): boolean {
    return day.isBetween(DatesFactory.fromExportedString(period.from), DatesFactory.fromExportedString(period.to).nextMonths(9));
  }

  private createOptionLabel(from: string, to: string, locale: string): string {
    const fromDate = DatesFactory.fromExportedString(from);
    const toDate = DatesFactory.fromExportedString(to);
    let fromTemplate = 'D MMM BBBB';
    const toTemplate = 'D MMM BBBB';

    if (fromDate.isSameYear(toDate)) {
      fromTemplate = 'D MMM';
    }

    const formattedFromDate = fromDate.locale(locale).format(fromTemplate);
    const formattedToDate = toDate.locale(locale).format(toTemplate);

    if (locale === 'th') {
      return `${formattedFromDate} ถึง ${formattedToDate}`;
    } else {
      return `${formattedFromDate} to ${formattedToDate}`;
    }
  }

  private createMonthPeriodLabel(from: string, to: string, locale: string): string {
    const fromDate = DatesFactory.fromExportedString(from);
    const toDate = DatesFactory.fromExportedString(to);
    let fromTemplate = 'MMM BBBB';
    const toTemplate = 'MMM BBBB';

    if (fromDate.isSameYear(toDate)) {
      fromTemplate = 'MMM';
    }

    const formattedFromDate = fromDate.locale(locale).format(fromTemplate);
    const formattedToDate = toDate.locale(locale).format(toTemplate);

    return `${formattedFromDate} - ${formattedToDate}`;
  }

  /**
   * createFiscalYearInfo
   * create information about fiscal year on a year with given fiscal end.
   * @param fiscalEnd string in "day/month" format
   * @param year year number in Christian Era
   * @returns fiscal year info
   */
  public createFiscalYearInfo(fiscalEnd: string, year: number): IFiscalYearInfo {
    const [date, month] = fiscalEnd.split('/', 2).map((value: string): number => Number.parseInt(value, 10));
    let fiscalDate = date;
    const fiscalMonth = month;
    if (date === 29 && month === 2) {
      fiscalDate = 28;
    }
    const fiscalYear: IFiscalYearInfo = {
      endOfLastFiscal: '',
      q1: { from: '', to: '' },
      q2: { from: '', to: '' },
      q3: { from: '', to: '' },
      q4: { from: '', to: '' },
    };

    // month parsing is zero indexed
    let cursor = DatesFactory.fromArray([year - 1, fiscalMonth - 1, fiscalDate]).tomorrow();
    fiscalYear.endOfLastFiscal = cursor.yesterday().locale('en').format(AppConfig.dateExportFormat);

    fiscalYear.q1.from = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.nextQuarter().yesterday();
    fiscalYear.q1.to = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.tomorrow();
    fiscalYear.q2.from = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.nextQuarter().yesterday();
    fiscalYear.q2.to = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.tomorrow();
    fiscalYear.q3.from = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.nextQuarter().yesterday();
    fiscalYear.q3.to = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.tomorrow();
    fiscalYear.q4.from = cursor.locale('en').format(AppConfig.dateExportFormat);

    cursor = cursor.nextQuarter().yesterday();
    fiscalYear.q4.to = cursor.locale('en').format(AppConfig.dateExportFormat);

    return fiscalYear;
  }
}

export default FiscalYearService;
