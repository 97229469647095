import { env } from '@core/env';
import SnapshotLanguage from '@enum/SnapshotLanguage.enum';
import ISaveSnapshotResponse from '@interfaces/ISaveSnapshotResponse';
import ISnapshotRadarResponse from '@interfaces/ISnapshotRadarResponse';
import { Snapshot } from '@models/snapshot';
import { SnapshotHtmlFragment } from '@models/snapshot-html-fragment';
import { SnapshotItem } from '@models/snapshot-item';
import { Stats } from '@models/stats';
import { AxiosResponse } from 'axios';
import axios from '../axios';
import downloadToBase64 from './downloadToBase64';

class SnapshotService {
  listBySymbol(symbol: string): Promise<SnapshotItem[]> {
    return axios.get<SnapshotItem[]>(`/snapshot?symbol=${symbol}`).then((resp: AxiosResponse<SnapshotItem[]>) => resp.data);
  }

  getStats(): Promise<Stats> {
    return axios.get<Stats>(`/snapshot/stats`).then((resp: AxiosResponse<Stats>) => resp.data);
  }

  create(symbol: string, period: string, fiscalYear: string, lang: SnapshotLanguage[]): Promise<Snapshot> {
    const params = {
      params: {
        symbol,
        period,
        fiscalYear,
        lang: lang.join(',').toLowerCase(),
      },
    };
    return axios
      .get(`/snapshot/create`, params)
      .then((resp: AxiosResponse<Snapshot>) => {
        return resp.data;
      })
      .then((snapshot: Snapshot) => {
        if (snapshot.company.logo == undefined) {
          return snapshot;
        }
        return downloadToBase64(snapshot.company.logo)
          .then((content: string) => {
            snapshot.company.logo = `data:image/png;base64,${content}`;
            return snapshot;
          })
          .catch(() => {
            return snapshot;
          });
      });
  }

  clone(id: number): Promise<Snapshot> {
    return axios
      .post(`/snapshot/${id}/clone`)
      .then((resp: AxiosResponse<Snapshot>) => {
        return resp.data;
      })
      .then((snapshot: Snapshot) => {
        if (snapshot.company.logo == undefined) {
          return snapshot;
        }
        return downloadToBase64(snapshot.company.logo)
          .then((content: string) => {
            snapshot.company.logo = `data:image/png;base64,${content}`;
            return snapshot;
          })
          .catch(() => {
            return snapshot;
          });
      });
  }

  submit(id: number): Promise<ISaveSnapshotResponse> {
    return axios.put(`/snapshot/${id}`).then(() => {
      return { id };
    });
  }

  cancelSubmit(id: number): Promise<ISaveSnapshotResponse> {
    return axios.put(`/snapshot/cancel/${id}`).then(() => {
      return { id };
    });
  }

  saveDraft(data: Snapshot): Promise<ISaveSnapshotResponse> {
    return axios.post('/snapshot/draft', data).then((resp: AxiosResponse<void>) => {
      const id = this.parseNewlyCreatedID(resp);

      return { id };
    });
  }

  deleteDraft(id: number): Promise<void> {
    return axios.delete(`/snapshot/${id}`);
  }

  updateDraft(data: Snapshot, id: number): Promise<ISaveSnapshotResponse> {
    return axios.put(`/snapshot/draft/${id}`, data).then(() => {
      return { id };
    });
  }

  load(id: number): Promise<Snapshot> {
    return axios.get(`/snapshot/${id}`).then((resp: AxiosResponse<Snapshot>) => {
      return resp.data;
    });
  }

  addLanguage(id: number, language: string): Promise<Snapshot> {
    return axios.get(`/snapshot/${id}/add/${language}`).then((resp: AxiosResponse<Snapshot>) => {
      return resp.data;
    });
  }

  getHtmlFragment(id: number): Promise<SnapshotHtmlFragment> {
    return axios.get(`/snapshot/${id}/html`).then((resp: AxiosResponse<SnapshotHtmlFragment>) => resp.data);
  }

  getHtmlFile(id: number): Promise<Blob> {
    return axios.get(`/snapshot/${id}.zip`, { responseType: 'blob' }).then((resp: AxiosResponse<Blob>) => resp.data);
  }

  getHtmlFileByLang(id: number, lang: SnapshotLanguage): Promise<Blob> {
    return axios.get(`/snapshot/${id}/${lang}.zip`, { responseType: 'blob' }).then((resp: AxiosResponse<Blob>) => resp.data);
  }

  getHtmlFileByLangURL(id: number, lang: SnapshotLanguage): string {
    return `${env.apiURL}/snapshot/${id}/${lang}.zip`;
  }

  approve(id: number): Promise<ISaveSnapshotResponse> {
    return axios.put(`snapshot/approve/${id}`).then(() => {
      return { id };
    });
  }

  reject(id: number, reason: string): Promise<ISaveSnapshotResponse> {
    return axios.put(`snapshot/reject/${id}`, { reason }).then(() => {
      return { id };
    });
  }

  radar(symbol: string, period: string, fiscalYear: string): Promise<ISnapshotRadarResponse> {
    return axios.get(`snapshot/radar`, { params: { symbol, period, fiscalYear } }).then((resp: AxiosResponse<ISnapshotRadarResponse>) => resp.data);
  }

  requestForCancelling(id: number): Promise<void> {
    return axios.put(`snapshot/request-for-cancelling/${id}`);
  }

  approveForCancelling(id: number): Promise<void> {
    return axios.put(`snapshot/approve-for-cancelling/${id}`);
  }

  cancelRequestForCancelling(id: number): Promise<void> {
    return axios.put(`snapshot/cancel-request-for-cancelling/${id}`);
  }

  rejectForCancelling(id: number): Promise<void> {
    return axios.put(`snapshot/reject-for-cancelling/${id}`);
  }

  bulkExportByType(period: string, fiscalYear: string, setList: string[], maiList: string[], languages: string[]): Promise<Blob> {
    const body = new FormData();
    body.append('period', period);
    body.append('fiscalYear', fiscalYear);
    body.append('sectors', setList.join(','));
    body.append('industries', maiList.join(','));
    body.append('lang', languages.join(','));
    body.append('filterBy', 'type');
    return axios({ method: 'post', url: `/snapshot/zip`, responseType: 'blob', data: body, headers: { 'Content-Type': 'multipart/form-data' } }).then(
      (resp: AxiosResponse<Blob>) => resp.data
    );
  }

  bulkExportBySymbols(period: string, fiscalYear: string, symbolList: string[], languages: string[]): Promise<Blob> {
    const body = new FormData();
    body.append('period', period);
    body.append('fiscalYear', fiscalYear);
    body.append('symbols', symbolList.join(','));
    body.append('lang', languages.join(','));
    body.append('filterBy', 'custom');
    return axios({ method: 'post', url: `/snapshot/zip`, responseType: 'blob', data: body, headers: { 'Content-Type': 'multipart/form-data' } }).then(
      (resp: AxiosResponse<Blob>) => resp.data
    );
  }

  private parseNewlyCreatedID(resp: AxiosResponse<void>): number {
    const location = resp.headers.location;
    const match = location.match(/\/snapshot\/(\d+)$/);

    if (match) {
      return Number.parseInt(match[1], 10);
    }
    throw new Error('New generated snapshot ID is not valid');
  }

  downloadPdf(id: number, lang: SnapshotLanguage): Promise<Blob> {
    const param = new URLSearchParams({ lang: lang });
    return axios({ method: 'get', url: `/snapshot/${id}/pdf`, responseType: 'blob', params: param, headers: { 'Content-Type': 'multipart/form-data' } }).then(
      (resp: AxiosResponse<Blob>) => resp.data
    );
  }

  fillByLatestData(symbol: string, period: string, fiscalYear: string, lang: SnapshotLanguage): Promise<Snapshot> {
    const params = new URLSearchParams({ symbol, period, fiscalYear, lang });
    return axios.get(`/snapshot/prefill`, { params: params }).then((resp: AxiosResponse<Snapshot>) => {
      return resp.data;
    });
  }
}

export default SnapshotService;
