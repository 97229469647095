interface ENV {
  apiURL: string;
  setlinkURL: string;
  zwizEnv: string;
  zwizClientSecret: string;
  chatBotUrl: string;
  chatBotAgentId: string;
  chatBotDlKey: string;
}

export const env: ENV = {
  apiURL: (process.env as Record<string, string>).REACT_APP_API_URL,
  setlinkURL: (process.env as Record<string, string>).REACT_APP_SETLINK_URL,
  zwizEnv: (process.env as Record<string, string>).REACT_APP_ZWIZ_ENV,
  zwizClientSecret: (process.env as Record<string, string>).REACT_APP_ZWIZ_CLIENT_SECRET,
  chatBotUrl: (process.env as Record<string, string>).REACT_APP_CHATBOT_URL,
  chatBotAgentId: (process.env as Record<string, string>).REACT_APP_CHATBOT_AGENT_ID,
  chatBotDlKey: (process.env as Record<string, string>).REACT_APP_CHATBOT_DL_KEY,
};
