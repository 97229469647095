import React from 'react';
export interface RouteConfig {
  key: string;
  path: string;
  exact: boolean;
  component: React.LazyExoticComponent<React.ComponentType<AnyValue>>;
}

export const routes: RouteConfig[] = [
  {
    key: 'preview',
    path: '/preview',
    exact: false,
    component: React.lazy(() => import('./pages/preview/Preview.page')),
  },
  {
    key: 'editor',
    path: '/editor',
    exact: false,
    component: React.lazy(() => import('./pages/editor/Editor.page')),
  },
  {
    key: 'terms',
    exact: true,
    path: '/terms',
    component: React.lazy(() => import('./pages/terms-and-condition/TermsAndCondition.page')),
  },
  {
    key: 'auth',
    exact: false,
    path: '/auth',
    component: React.lazy(() => import('./pages/auth/Auth.page')),
  },
  {
    key: 'home',
    exact: true,
    path: '/dashboard',
    component: React.lazy(() => import('./pages/home-page/HomePage.page')),
  },
  {
    key: 'admin',
    exact: true,
    path: '/admin',
    component: React.lazy(() => import('./pages/report/Report.page')),
  },
  {
    key: 'reception',
    exact: true,
    path: '/',
    component: React.lazy(() => import('./pages/reception/Reception.page')),
  },
];
