export interface SnapshotVisibility {
  companyRating: boolean;
  award: boolean;
  consistencyPlan: boolean;
  addOnLeft: boolean;
  addOnRight: boolean;
}

export function emptySnapshotVisibility(): SnapshotVisibility {
  return {
    companyRating: true,
    award: true,
    consistencyPlan: true,
    addOnLeft: false,
    addOnRight: false,
  };
}
