import { CaseOwnerContact } from '../../@models/case-owner-contact';
import axios from '../axios';
import { AxiosResponse } from 'axios';

class ContactService {
  getContact(): Promise<CaseOwnerContact[]> {
    return axios.get<CaseOwnerContact[]>(`/contact`).then((resp: AxiosResponse<CaseOwnerContact[]>) => resp.data);
  }
}

export default ContactService;
