import i18n from 'i18next';
import { TranslationEn } from '@constants/i18n/en/translation';
import { TranslationTh } from '@constants/i18n/th/translation';
import { initReactI18next } from 'react-i18next';
import ITranslation from '@interfaces/ITranslation';
import AppConfig from '@constants/app-config';

type ResourcesTranslation = {
  en: { translation: ITranslation };
  th: { translation: ITranslation };
};

export const resources: ResourcesTranslation = {
  en: {
    translation: TranslationEn,
  },
  th: {
    translation: TranslationTh,
  },
};

i18n.use(initReactI18next).init({
  lng: AppConfig.defaultI18nLanguage,
  resources,
});

export default i18n;
