import axios from '@core/axios';
import { SnapshotFinancialData } from '@models/snapshot';
import { StockStatistic } from '@models/stock-statistic';
import { AxiosResponse } from 'axios';
import { MarketComparison } from './marketComparisonToTable';

class StockService {
  getFinancialData(symbol: string, period: string, year: string, isFundAndReit?: boolean): Promise<SnapshotFinancialData> {
    const basePath = isFundAndReit ? 'property-fund' : 'stock';
    return axios.get<SnapshotFinancialData>(`/${basePath}/${symbol}/financial-data?period=${period}&year=${year}`).then((resp: AxiosResponse<SnapshotFinancialData>) => resp.data);
  }

  get(endOfPeriod: string, symbol: string, type: string, market: string): Promise<Array<MarketComparison>> {
    return axios.get<Array<MarketComparison>>(`/stock/${symbol}/market-comparisons?endOfPeriod=${endOfPeriod}&type=${type}&market=${market}`).then((response) => {
      return response.data;
    });
  }

  getStatistics(fiscalYear: string, period: string, symbol: string): Promise<Array<StockStatistic>> {
    return axios.get<Array<StockStatistic>>(`/stock/${symbol}/statistics?fiscalYear=${fiscalYear}&period=${period}`).then((response) => {
      return response.data;
    });
  }
}

export default StockService;
