import React, { FC, useEffect, useState } from 'react';
import setlinkLogo from '@assets/images/setlink-logo.png';

import './Header.scss';
import { env } from '@core/env';
import { useUserState } from '@context/User.context';
import { useDependencies } from '@context/Dependencies.context';
import { CaseOwnerContact } from '@models/case-owner-contact';
import { useTranslation } from 'react-i18next';

const Header: FC = () => {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const userState = useUserState();
  const { contactService } = useDependencies();
  const [activeRmInformationPopover, setActiveRmInformationPopover] = useState(false);
  const [contacts, setContacts] = useState<CaseOwnerContact[]>([]);

  useEffect(() => {
    if (userState) {
      contactService.getContact().then((data) => {
        setContacts(data);
      });
    }
  }, [contactService, userState]);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setCurrentLanguage(lng);
  };

  function openSetlink() {
    window.open(env.setlinkURL);
  }

  function getRoles() {
    return userState?.roles.join(' | ');
  }

  function openRmInformationPopover() {
    setActiveRmInformationPopover(!activeRmInformationPopover);
  }

  return (
    <div className="header__container">
      <div className="header__container--left">
        <img className="setlink-logo" src={setlinkLogo} width="73" height="25" onClick={openSetlink} />
      </div>
      <div className="header__container--right">
        <div className={'profile'}>
          <div data-testid={'name-profile-header'} className={'profile--name'}>
            {userState?.fullName}
          </div>
          <div data-testid={'roles-profile-header'} className={'profile--roles'}>
            {getRoles()}
          </div>
        </div>
        <span data-testid="rm-contact-button" className={'rm-icon'} onClick={() => openRmInformationPopover()} />
        <span data-testid="th-change-language-button" className={`i18n-icon i18n-icon__th ${currentLanguage === 'th' ? 'active' : ''}`} onClick={() => changeLanguage('th')} />
        <span data-testid="en-change-language-button" className={`i18n-icon i18n-icon__en ${currentLanguage === 'en' ? 'active' : ''}`} onClick={() => changeLanguage('en')} />
      </div>
      <div data-testid={'rm-contact-popover'} className={`popover ${activeRmInformationPopover ? 'active' : ''}`}>
        <div className="popover-content">
          <div className="popover-arrow">
            <span className="popover-arrow-content" />
          </div>
          <div className="popover-inner" role="tooltip">
            <div className="popover-inner-title">{t('contactRelationShipManager')}</div>
            {contacts.map((contact, index) => {
              return (
                <div key={index} className="popover-inner-content">
                  <div>
                    <span className={'label'}>{t('fullName')}: </span>
                    <span data-testid={'contact-name'}>
                      {currentLanguage === 'th' ? `${contact.firstNameThai} ${contact.lastNameThai}` : `${contact.firstNameEng} ${contact.lastNameEng}`}
                    </span>
                  </div>
                  <div>
                    <span className={'label'}>{t('officePhoneNumber')}: </span>
                    <span data-testid={'contact-office-number'}>{contact.officeNumber}</span>
                  </div>
                  <div>
                    <span className={'label'}>{t('email')}: </span>
                    <span data-testid={'contact-email'}>{contact.email}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
