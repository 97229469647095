import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';

const Footer: FC = () => {
  const { t } = useTranslation();

  return <div className="page-footer">{t('footerMessage')}</div>;
};

export default Footer;
