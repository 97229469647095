import IAppConfig from '../@interfaces/IAppConfig';

const AppConfig: IAppConfig = {
  revenueStructure: {
    max: 7,
    width: 310,
    barHeight: 16,
    barMaxWidth: 100,
    barGap: 4,
    barRadius: 4,
    barValueGap: 4,
    labelWidth: 150,
    labelOffsetY: 3,
    separatorWidth: 1,
    separatorLeftGap: 4,
    separatorOffsetY: -2,
    separatorHeight: 20,
    separatorRightGap: 4,
    otherSourceLabel: 'อื่น ๆ',
  },
  shareHolders: {
    width: 306,
    height: 38,
    borderRadius: 16,
  },
  priceHistory: {
    canvas: {
      width: 320,
      height: 170,
    },
    chartArea: {
      width: 237,
      height: 130,
    },
    legend: {
      height: 20,
      width: 0,
    },
    stock: {
      label: {
        height: 10,
        width: 36,
      },
      topPortion: 0.2,
      bottomPortion: 0.4,
      offset: {
        top: 0,
        left: 0,
      },
    },
    market: {
      label: {
        height: 10,
        width: 36,
      },
      topPortion: 0.1,
      bottomPortion: 0.3,
      offset: {
        top: 0,
        left: 0,
      },
    },
    date: {
      label: {
        height: 10,
        width: 36,
      },
      offset: {
        top: 14,
        left: 0,
      },
    },
  },
  defaultI18nLanguage: 'th',
  dateExportFormat: 'YYYY-MM-DD',
  defaultColorScheme: 'orange-2',
  adminMaxSelectableSymbols: 0, // set to 0 to allow unlimited selection
};

export default AppConfig;
