import SnapshotConstant from '@constants/snapshot';
import React, { createContext, useContext, useReducer } from 'react';

import { snapshotIdReducer } from '../@reducers/SnapshotId.reducer';
import SnapshotIdDispatch from '../@types/SnapshotIdDispatch';
import SnapshotIdState from '../@types/SnapshotIdState';

const SnapshotIdStateContext = createContext<SnapshotIdState>(undefined);
const SnapshotIdDispatchContext = createContext<SnapshotIdDispatch | undefined>(undefined);

const SnapshotIdProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const initialState = SnapshotConstant.NoID;
  const [state, dispatch] = useReducer(snapshotIdReducer, initialState);
  return (
    <SnapshotIdStateContext.Provider value={state}>
      <SnapshotIdDispatchContext.Provider value={dispatch}>{children}</SnapshotIdDispatchContext.Provider>
    </SnapshotIdStateContext.Provider>
  );
};

const useSnapshotIdState = (): SnapshotIdState => {
  const context = useContext(SnapshotIdStateContext);
  if (context === undefined) {
    throw new Error('useSnapshotIdState must be used within a SnapshotIdProvider');
  }
  return context;
};

const useSnapshotIdDispatch = (): SnapshotIdDispatch => {
  const context = useContext(SnapshotIdDispatchContext);
  if (context === undefined) {
    throw new Error('useSnapshotIdDispatch must be used within a SnapshotIdProvider');
  }
  return context;
};

export { SnapshotIdProvider, useSnapshotIdState, useSnapshotIdDispatch };
