import React, { createContext, useReducer } from 'react';
import TokenService from '../@core/services/token.service';

type Action = { type: 'SET_TOKEN'; payload: string } | { type: 'SET_USER_AGREEMENT'; payload: boolean };
type Dispatch = (action: Action) => void;
type State = { token: string; userAgreement: boolean };

const TokenStateContext = createContext<State | undefined>(undefined);
const TokenDispatchContext = createContext<Dispatch | undefined>(undefined);

function reducer(state: State, action: Action): State {
  if (action.type === 'SET_TOKEN') {
    return { ...state, token: action.payload };
  }
  if (action.type === 'SET_USER_AGREEMENT') {
    return { ...state, userAgreement: action.payload };
  }
  throw new Error(`Unhandled action type: ${(action as Action).type}`);
}

const TokenProvider: React.FC = ({ children }: { children?: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    token: TokenService.getToken() ?? '',
    userAgreement: sessionStorage.getItem('accept-tnc') === 'true',
  });

  return (
    <TokenStateContext.Provider value={state}>
      <TokenDispatchContext.Provider value={dispatch}>{children}</TokenDispatchContext.Provider>
    </TokenStateContext.Provider>
  );
};

const useTokenState = (): State => {
  const context = React.useContext(TokenStateContext);
  if (context === undefined) {
    throw new Error('useTokenState must be used within a TokenProvider');
  }
  return context;
};

const useTokenDispatch = (): Dispatch => {
  const context = React.useContext(TokenDispatchContext);
  if (context === undefined) {
    throw new Error('useTokenDispatch must be used within a TokenProvider');
  }
  return context;
};

export { TokenProvider, useTokenState, useTokenDispatch };
