enum SnapshotStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  REQ_FOR_CANCELLING = 'REQ_FOR_CANCELLING',
  DRAFT_AFTER_APPROVED = 'DRAFT_AFTER_APPROVED',
}

export default SnapshotStatus;
