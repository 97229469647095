import AppConfig from '@constants/app-config';
import { localeObject } from '@constants/dayjs/locale/th';
import FiscalYearService from '@core/services/FiscalYearService.service';
import dayjs, { Dayjs } from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import LocaleData from 'dayjs/plugin/localeData';
import TimeZone from 'dayjs/plugin/timezone';
import UTC from 'dayjs/plugin/utc';

class Dates {
  private _dayjs: Dayjs;
  private _locale: string;

  constructor(date: number | string | [number?, number?, number?, number?, number?, number?, number?] | Dayjs | undefined | Date) {
    dayjs.locale(localeObject, undefined, true);
    dayjs.extend(UTC);
    dayjs.extend(TimeZone);
    dayjs.extend(IsBetween);
    dayjs.extend(CustomParseFormat);
    dayjs.extend(LocaleData);
    dayjs.extend(BuddhistEra);
    dayjs.extend(arraySupport);
    this._locale = 'th';

    this._dayjs = dayjs();
    if (typeof date !== undefined) {
      if (typeof date === 'object' && date.constructor['name'] === 'Dayjs') {
        this._dayjs = (date as unknown as Dayjs).clone();
      } else {
        this._dayjs = dayjs(date);
      }
    }
  }

  locale(localeName: string): Dates {
    const date = new Dates(this._dayjs);
    date.setLocale(localeName);
    return date;
  }

  setLocale(localeName: string): void {
    this._locale = localeName;
  }

  format(layout: string): string {
    if (this._locale === 'th') {
      if (layout.indexOf('YYYY') >= 0) {
        layout = layout.replace(/YYYY/g, 'BBBB');
      }
      if (layout.indexOf('YY') >= 0) {
        layout = layout.replace(/YY/g, 'BB');
      }
    } else {
      if (layout.indexOf('BBBB') >= 0) {
        layout = layout.replace(/BBBB/g, 'YYYY');
      }
      if (layout.indexOf('BB') >= 0) {
        layout = layout.replace(/BB/g, 'YY');
      }
    }
    return this._day().format(layout);
  }

  isBefore(otherDate: Dates): boolean {
    return this.locale('en').format('YYYYMMDD') < otherDate.locale('en').format('YYYYMMDD');
  }

  isAfter(otherDate: Dates): boolean {
    return this.locale('en').format('YYYYMMDD') > otherDate.locale('en').format('YYYYMMDD');
  }

  isSame(otherDate: Dates): boolean {
    return this.locale('en').format('YYYYMMDD') === otherDate.locale('en').format('YYYYMMDD');
  }

  isBeforeOrSame(otherDate: Dates): boolean {
    return this.isBefore(otherDate) || this.isSame(otherDate);
  }

  isAfterOrSame(otherDate: Dates): boolean {
    return this.isAfter(otherDate) || this.isSame(otherDate);
  }

  isBeforeToday(): boolean {
    const today = DatesFactory.today();
    return this.isBefore(today);
  }

  // Close between check [from, to]
  isBetween(fromDate: Dates, toDate: Dates): boolean {
    return this.isAfterOrSame(fromDate) && this.isBeforeOrSame(toDate);
  }

  tomorrow(): Dates {
    return DatesFactory.fromDayJS(this._day().add(1, 'day'));
  }

  yesterday(): Dates {
    return DatesFactory.fromDayJS(this._day().subtract(1, 'day'));
  }

  nextQuarter(): Dates {
    return DatesFactory.fromDayJS(this._day().add(3, 'month'));
  }

  nextMonths(amount: number): Dates {
    return DatesFactory.fromDayJS(this._day().add(amount, 'month'));
  }

  year(): number {
    return this._day().year();
  }

  isSameYear(otherDate: Dates): boolean {
    return this.year() === otherDate.year();
  }

  toUTC(): string {
    return this._day().format();
  }

  private _day(): Dayjs {
    return this._dayjs.tz('Asia/Bangkok').locale(this._locale);
  }
}

const DatesFactory = {
  today: (): Dates => {
    return new Dates(undefined);
  },

  fromDayJS: (object: Dayjs): Dates => {
    return new Dates(object);
  },

  fromFiscal: (fiscalEnd: string, period: string, fiscalYear: string): Dates => {
    const fiscalYearService = new FiscalYearService();
    const christianFiscalYear = Number.parseInt(fiscalYear, 10) - 543;
    const fiscalInfo = fiscalYearService.createFiscalYearInfo(fiscalEnd, christianFiscalYear);
    switch (period) {
      case '3M':
        return DatesFactory.fromExportedString(fiscalInfo.q1.to);
      case '6M':
        return DatesFactory.fromExportedString(fiscalInfo.q2.to);
      case '9M':
        return DatesFactory.fromExportedString(fiscalInfo.q3.to);
      default:
        return DatesFactory.fromExportedString(fiscalInfo.q4.to);
    }
  },

  fromLocaleLayout: (dateString: string, layout: string, locale: string): Dates => {
    const d = dayjs(dateString, layout, locale);
    return new Dates(d);
  },

  fromLayout: (dateString: string, layout: string): Dates => {
    const d = dayjs(dateString, layout);
    return new Dates(d);
  },

  fromExportedString: (exportedString: string): Dates => {
    return DatesFactory.fromLayout(exportedString, AppConfig.dateExportFormat);
  },

  fromDDSlashMMSlashBBBB: (ddSlashMMSlashBBBB: string): Dates => {
    if (typeof ddSlashMMSlashBBBB === 'undefined') {
      return new Dates(undefined);
    }

    const dateParts = ddSlashMMSlashBBBB.split('/');
    const dd = Number.parseInt(dateParts[0], 10);
    const mm = Number.parseInt(dateParts[1], 10);
    const bbbb = Number.parseInt(dateParts[2], 10);

    return DatesFactory.fromArray([bbbb - 543, mm - 1, dd]);
  },

  fromISOString: (isoString: string | undefined): Dates => {
    return new Dates(isoString);
  },

  fromEpochSeconds: (epochSeconds: number | undefined): Dates => {
    if (typeof epochSeconds === 'undefined') {
      return new Dates(undefined);
    }
    return new Dates(epochSeconds * 1000);
  },

  fromArray: (dateInArray: [number?, number?, number?, number?, number?, number?, number?]): Dates => {
    return new Dates(dateInArray);
  },

  fromDate: (date: Date): Dates => {
    return new Dates(date);
  },
};

export { Dates, DatesFactory };
