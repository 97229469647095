import ContactService from '@core/services/ContactService.service';
import FiscalYearService from '@core/services/FiscalYearService.service';
import ProfileService from '@core/services/ProfileService.service';
import ReportService from '@core/services/ReportService.service';
import SnapshotService from '@core/services/SnapshotService.service';
import StockService from '@core/services/StockService.service';
import IInjectedDependencies from '@interfaces/IInjectedDependencies';
import React, { createContext, useContext } from 'react';

interface IDependenciesProviderProps {
  children?: React.ReactNode;
  snapshotService: SnapshotService;
  reportService: ReportService;
  profileService: ProfileService;
  fiscalYearService: FiscalYearService;
  stockService: StockService;
  contactService: ContactService;
}

const DependenciesContext = createContext<IInjectedDependencies>({
  snapshotService: new SnapshotService(),
  reportService: new ReportService(),
  profileService: new ProfileService(),
  fiscalYearService: new FiscalYearService(),
  stockService: new StockService(),
  contactService: new ContactService(),
});

const DependenciesProvider: React.FC<IDependenciesProviderProps> = ({
  children,
  snapshotService,
  reportService,
  profileService,
  fiscalYearService,
  stockService,
  contactService,
}: IDependenciesProviderProps) => {
  const services: IInjectedDependencies = { snapshotService, reportService, profileService, fiscalYearService, stockService, contactService };
  return <DependenciesContext.Provider value={services}>{children}</DependenciesContext.Provider>;
};

const useDependencies = (): IInjectedDependencies => {
  const context = useContext(DependenciesContext);
  if (context === undefined) {
    throw new Error('useDependencies must be used within a DependenciesProvider');
  }
  return context;
};

export { DependenciesProvider, useDependencies };
