export interface SnapshotCompany {
  nameTh: string;
  nameEn: string;
  symbol: string;
  logo?: string;
  fiscalEnd?: string;
  industry: string;
  industryEn?: string;
  industryTh?: string;
  market: string;
  sector: string;
  sectorEn?: string;
  sectorTh?: string;
  cgRemarkTh?: string;
  cgRemarkEn?: string;
  cgScore?: number;
}

export function initSnapshotCompany(nameEn: string, nameTh: string, symbol: string, logo: string, fiscalEnd: string): SnapshotCompany {
  const empty = emptySnapshotCompany();
  empty.nameEn = nameEn;
  empty.nameTh = nameTh;
  empty.symbol = symbol;
  empty.logo = logo;
  empty.fiscalEnd = fiscalEnd;

  return empty;
}

export function emptySnapshotCompany(): SnapshotCompany {
  return {
    nameEn: '',
    nameTh: '',
    symbol: '',
    industry: '',
    industryEn: '',
    industryTh: '',
    logo: '',
    market: '',
    sector: '',
    sectorEn: '',
    sectorTh: '',
    cgRemarkTh: '',
    cgRemarkEn: '',
    cgScore: 0,
  };
}

export function selectCompanyType(company: SnapshotCompany): string {
  return company.market === 'SET' ? company.sector : company.industry;
}
