import { Profile } from '@models/profile';
import { AxiosResponse } from 'axios';
import axios from '../../@core/axios';

class ProfileService {
  get(): Promise<Profile> {
    return axios.get<Profile>('/profile').then((resp: AxiosResponse<Profile>) => resp.data);
  }
}

export default ProfileService;
