import SnapshotConstant from '@constants/snapshot';
import SnapshotIdAction from '@enum/SnapshotIdAction.enum';
import ISnapshotIdAction from '../@interfaces/ISnapshotIdAction';
import SnapshotIdState from '../@types/SnapshotIdState';

function snapshotIdReducer(state: SnapshotIdState, action: ISnapshotIdAction): SnapshotIdState {
  let newState: SnapshotIdState = state;
  switch (action.type) {
    case SnapshotIdAction.INITIAL_SNAPSHOT_ID:
      newState = action.payload;
      break;
    case SnapshotIdAction.RESET_SNAPSHOT_ID:
      newState = SnapshotConstant.NoID;
      break;
  }
  return newState;
}

export { snapshotIdReducer };
