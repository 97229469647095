const localeObject = {
  name: 'th',
  weekdays: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
  weekdaysShort: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
  weekdaysMin: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
  weekStart: 1,
  ordinal: (n: number): string => `${n}`,
  monthsShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
  months: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
  formats: {
    LTS: 'h:mm:ss',
    LT: 'h:mm',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY h:mm A',
    LLLL: 'dddd D MMMM YYYY h:mm A',
    // lowercase/short, optional formats for localization
    l: 'D/M/YYYY',
    ll: 'D MMM YYYY',
    lll: 'D MMM YYYY h:mm',
    llll: 'ddd D MMM YYYY h:mm',
  },
  relativeTime: {
    future: 'ในอีก %s', // e.g. in 2 hours, %s been replaced with 2hours
    past: '%s ที่ผ่านมา',
    s: 'ไม่กี่วินาที',
    m: 'หนึ่งนาที',
    mm: '%d นาที',
    h: 'หนึ่งชั่วโมง',
    hh: '%d ชั่วโมง', // e.g. 2 hours, %d been replaced with 2
    d: 'หนึ่งวัน',
    dd: '%d วัน',
    M: 'หนึ่งเดือน',
    MM: '%d เดือน',
    y: 'หนึ่งปี',
    yy: '%d ปี',
  },
};

export { localeObject };
