import AppConfig from '@constants/app-config';
import SnapshotLanguage from '@enum/SnapshotLanguage.enum';
import ISnapshotMeta from '@interfaces/ISnapshotMeta';
import { emptySnapshot, Snapshot, SnapshotData, SnapshotFinancialData } from '@models/snapshot';
import { emptySnapshotCompany, SnapshotCompany } from '@models/snapshot-company';
import { emptySnapshotVisibility, SnapshotVisibility } from '@models/snapshot-visibility';
import _ from 'lodash';
import SnapshotAction from '../@enum/SnapshotAction.enum';
import ISnapshotAction from '../@interfaces/ISnapshotAction';
import SnapshotState from '../@types/SnapshotState';

function snapshotReducer(state: SnapshotState, action: ISnapshotAction): SnapshotState {
  const baseState = {} as Snapshot;
  let currentData: SnapshotData = {};
  let currentFinancialData: SnapshotFinancialData = {};
  let currentCompanyData: SnapshotCompany = emptySnapshotCompany();
  let currentVisibilityData: SnapshotVisibility = emptySnapshotVisibility();

  let newSnapshot: Snapshot = emptySnapshot();

  switch (action.type) {
    case SnapshotAction.MAKE_UNTOUCHED:
      newSnapshot = { _touched: false } as Snapshot;
      break;
    case SnapshotAction.INITIAL_SNAPSHOT:
      newSnapshot = action.payload as Snapshot;
      newSnapshot._touched = false;
      if (!newSnapshot.colorScheme) {
        // Force set
        newSnapshot.colorScheme = AppConfig.defaultColorScheme;
      }
      break;
    case SnapshotAction.UPDATE_SNAPSHOT:
      newSnapshot = action.payload as Snapshot;
      break;
    case SnapshotAction.UPDATE_META:
      newSnapshot = {
        _touched: true,
        period: (action.payload as ISnapshotMeta)?.period,
        fiscalYear: (action.payload as ISnapshotMeta)?.fiscalYear,
        availableLanguages: (action.payload as ISnapshotMeta)?.languages,
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_PROFILE_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = { _touched: true, dataTh: Object.assign({}, currentData, { profile: action.payload }) } as Snapshot;
      break;
    case SnapshotAction.UPDATE_PROFILE_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { profile: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_REMARKABLE_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { remarkable: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_REMARKABLE_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { remarkable: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_REVENUE_STRUCTURE_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { revenueStructure: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_REVENUE_STRUCTURE_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { revenueStructure: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_PROGRESS_SUMMARY_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { progressSummary: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_PROGRESS_SUMMARY_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { progressSummary: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_SIGNIFICANT_IMPROVEMENT_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { significantImprovement: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_SIGNIFICANT_IMPROVEMENT_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { significantImprovement: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_BUSINESS_PLAN_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { businessPlan: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_BUSINESS_PLAN_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { businessPlan: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CONSISTENCY_PLAN_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { consistencyPlan: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CONSISTENCY_PLAN_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { consistencyPlan: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_SIGNIFICANT_RISK_MANAGEMENT_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { significantRiskManagement: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_SIGNIFICANT_RISK_MANAGEMENT_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { significantRiskManagement: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_AWARD_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { award: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_AWARD_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { award: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_SHARES_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { shareHolders: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_SHARES_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { shareHolders: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CONTACT_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { contact: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CONTACT_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { contact: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_COMPANY_RATING_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { companyRating: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_COMPANY_RATING_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { companyRating: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_INCOME_STATEMENT:
      currentFinancialData = cloneFinanceData(state);
      newSnapshot = {
        _touched: true,
        financialData: Object.assign({}, currentFinancialData, { incomeStatement: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_FINANCIAL_PROPORTION:
      currentFinancialData = cloneFinanceData(state);
      newSnapshot = {
        _touched: true,
        financialData: Object.assign({}, currentFinancialData, { financialProportion: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_FINANCIAL_STATUS:
      currentFinancialData = cloneFinanceData(state);
      newSnapshot = {
        _touched: true,
        financialData: Object.assign({}, currentFinancialData, { financialStatus: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CASH_FLOW:
      currentFinancialData = cloneFinanceData(state);
      newSnapshot = {
        _touched: true,
        financialData: Object.assign({}, currentFinancialData, { cashFlow: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_COMPANY_LOGO:
      currentCompanyData = cloneCompanyData(state);
      newSnapshot = {
        _touched: true,
        company: Object.assign({}, currentCompanyData, { logo: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_COMPANY_RATING_VISIBILITY:
      currentVisibilityData = cloneVisibility(state);
      newSnapshot = {
        _touched: true,
        visibility: Object.assign({}, currentVisibilityData, { companyRating: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_AWARD_VISIBILITY:
      currentVisibilityData = cloneVisibility(state);
      newSnapshot = {
        _touched: true,
        visibility: Object.assign({}, currentVisibilityData, { award: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CONSISTENCY_PLAN_VISIBILITY:
      currentVisibilityData = cloneVisibility(state);
      newSnapshot = {
        _touched: true,
        visibility: Object.assign({}, currentVisibilityData, { consistencyPlan: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_COLOR_SCHEME:
      newSnapshot = {
        _touched: true,
        colorScheme: action.payload,
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_MARKET_COMPARISONS:
      newSnapshot = {
        _touched: true,
        marketComparisons: action.payload,
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_STOCK_STATISTICS:
      newSnapshot = {
        _touched: true,
        stockStatistics: action.payload,
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CLOSING_DATE_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { shareHoldersBookClosingDate: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_CLOSING_DATE_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { shareHoldersBookClosingDate: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_LEFT_VISIBILITY:
      currentVisibilityData = cloneVisibility(state);
      newSnapshot = {
        _touched: true,
        visibility: Object.assign({}, currentVisibilityData, { addOnLeft: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_RIGHT_VISIBILITY:
      currentVisibilityData = cloneVisibility(state);
      newSnapshot = {
        _touched: true,
        visibility: Object.assign({}, currentVisibilityData, { addOnRight: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_LEFT_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { addOnLeft: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_LEFT_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { addOnLeft: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_RIGHT_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { addOnRight: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_RIGHT_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { addOnRight: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_SEQUENCE_EN:
      currentData = cloneData(state, SnapshotLanguage.EN);
      newSnapshot = {
        _touched: true,
        dataEn: Object.assign({}, currentData, { boxSequence: action.payload }),
      } as Snapshot;
      break;
    case SnapshotAction.UPDATE_ADD_ON_SEQUENCE_TH:
      currentData = cloneData(state, SnapshotLanguage.TH);
      newSnapshot = {
        _touched: true,
        dataTh: Object.assign({}, currentData, { boxSequence: action.payload }),
      } as Snapshot;
      break;
  }
  return Object.assign(baseState, state, newSnapshot);
}

function cloneVisibility(state: SnapshotState): SnapshotVisibility {
  if (state && state.visibility) {
    return _.cloneDeep(state.visibility);
  }

  return emptySnapshotVisibility();
}

function cloneCompanyData(state: SnapshotState): SnapshotCompany {
  if (state && state.company) {
    return _.cloneDeep(state.company);
  }

  return emptySnapshotCompany();
}

function cloneFinanceData(state: SnapshotState): SnapshotFinancialData {
  if (state && state.financialData) {
    return _.cloneDeep(state.financialData);
  }

  return {};
}

function cloneData(state: SnapshotState, language: SnapshotLanguage): SnapshotData {
  if (state && state.dataTh && language === SnapshotLanguage.TH) {
    return _.cloneDeep(state.dataTh);
  }

  if (state && state.dataEn && language === SnapshotLanguage.EN) {
    return _.cloneDeep(state.dataEn);
  }

  return {};
}

export { snapshotReducer };
