export default function downloadToBase64(url: string): Promise<string> {
  if (validUrl(url)) {
    return fetch(`${url}?date=${new Date().toString()}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then(function (response) {
        return response.arrayBuffer();
      })
      .then(base64FromArrayBuffer);
  } else {
    return Promise.reject('Not a valid URL');
  }
}

function validUrl(url: string): boolean {
  return /http(s)?:\/\/(\w+:?\w*@)?(\S+)(:\d+)?((?<=\.)\w+)+(\/([\w#!:.?+=&%@!\-/])*)?/gi.test(url);
}

function base64FromArrayBuffer(buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
