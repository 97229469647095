import { AxiosResponse } from 'axios';
import axios from '../axios';

class ReportService {
  downloadSnapshotHistory(fiscalYear: string, period: string): Promise<Blob> {
    const params = { fiscalYear: fiscalYear?.toUpperCase(), period: period?.toUpperCase() };
    return axios.get(`/report/snapshot`, { params, responseType: 'blob' }).then((resp: AxiosResponse<Blob>) => resp.data);
  }
}

export default ReportService;
