import React, { FC } from 'react';
import { env } from '../../@core/env';
import { BullIsComing, BullIsComingEnvType, BullIsComingProcessProps, ZWIZAccessTokenCallbackProps } from '@mis/the-bull-is-coming';
import { useTokenState } from '../../@context/Token.context';
import TokenService from '../../@core/services/token.service';

const BullChatbot: FC = () => {
  const { token, userAgreement } = useTokenState();

  const eventRequestAuthToken: ZWIZAccessTokenCallbackProps['eventRequestAuthToken'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback(data.data.token);
      },
    });
  };

  const eventRequestRefreshAuthenToken: BullIsComingProcessProps['eventRequestRefreshAuthenToken'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback(data.data.token);
      },
    });
  };

  const eventRequestRefreshISPToken: BullIsComingProcessProps['eventRequestRefreshISPToken'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback(data.data.token);
      },
    });
  };

  const eventRequestChatBot: BullIsComingProcessProps['eventRequestChatBot'] = (callback) => {
    TokenService.refreshToken().subscribe({
      next: (data) => {
        callback({ token: data.data.token });
      },
    });
  };

  if (token && userAgreement) {
    return (
      <BullIsComing
        zwizEnv={env.zwizEnv as BullIsComingEnvType}
        zwizClientId="set-link"
        zwizClientSecret={env.zwizClientSecret}
        chatbotUrl={env.chatBotUrl}
        chatbotAgentId={env.chatBotAgentId}
        chatbotDLKey={env.chatBotDlKey}
        eventRequestAuthToken={eventRequestAuthToken}
        eventRequestRefreshAuthenToken={eventRequestRefreshAuthenToken}
        eventRequestRefreshISPToken={eventRequestRefreshISPToken}
        eventRequestChatBot={eventRequestChatBot}
        zwizStyle={{ marginBottom: '4.4px', marginRight: '6px' }}
      />
    );
  }
  return null;
};
export default BullChatbot;
